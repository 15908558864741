export const Baseurl = "https://qudo.airwebsolutions.co.uk/api/";

// export const Baseurl = "http://127.0.0.1:8000/api/";

export const LoginUrl = Baseurl + "login";

export const RegisterUrl = Baseurl + "register";

export const GetJobList = Baseurl + "job_list";

export const GetJobListUser = Baseurl + "job_list_user";

export const GetProfile = Baseurl + "get_user_profile";

export const GetUserList = Baseurl + "get_user_list";

export const GetCountryList = Baseurl + "getCountriesForContinents";

export const GetStateList = Baseurl + "getStatesForCountries";

export const UserApprove = Baseurl + "user_approved";

export const UserReject = Baseurl + "user_rejected";

export const AddRole = Baseurl + "add_role";

export const SearchCompany = Baseurl + "search";

export const ForgotPassword = Baseurl + "forgot_password";

export const GetJobDetails = Baseurl + "job_details";

export const EditJobRole = Baseurl + "edit_job_role";

export const SetLogo = Baseurl + "set_logo";

export const GetUsers = Baseurl + "get_users";

export const GetIndustries = Baseurl + "get_industries";

export const EditJobStatus = Baseurl + "edit_job_status";

export const userAppliedJob = Baseurl + "get-users-applied-job";

export const userApplicationStatus = Baseurl + "update-application-status";

export const GetContinents = Baseurl + "get_continent_list";

export const GetCurrency = Baseurl + "get_currency_list";

export const Commission = Baseurl + "commission-details";



