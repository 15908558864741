import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography, CircularProgress, Box, Modal, Button, Select, MenuItem } from "@mui/material";
import * as Constant from "../Constant";



const ApplicantTableRow = ({ item }) => {
    const [updateDrop, setUpdatDrop] = useState({});
    const [previous, setPrevious] = useState("")
    const navigate = useNavigate()
    const location = useLocation()
    const [terms, setTerms] = useState(false);

    useEffect(() => {
        if(Object.keys(item).length > 0) {
            setUpdatDrop(item);
        }
    }, [item])

    const jobType = [
        {
            txtid: 51,
            application_status: "Unsuccessful at application stage",
            val: "unsuccessful"
        },
        {
            txtid: 52,
            application_status: "Application under review",
            val: "under_review"
        },
        {
            txtid: 53,
            application_status: "Unsuccessful at review stage",
            val: "unsuccessful_review"
        },
        {
            txtid: 54,
            application_status: "Invited to interview",
            val: "invited_to_interview"
        },
        {
            txtid: 56,
            application_status: "Job offer",
            val: "job_offer"
        },
        {
            txtid: 58,
            application_status: "Hired",
            val: "hired"
        },
        {
            txtid: 59,
            application_status: "Unsuccessful at interview stage",
            val: "unsuccessful_interview"
        },
    ];

    const handleChange = async (e) => {
        const value = e.target.value;
        setPrevious(updateDrop.application_status)
        setUpdatDrop({ ...updateDrop, application_status: value })
        if (value === 'hired') {
            setTerms(true)
        } else {
            const formData = new FormData();
            formData.append("status", value);
            apiFunction(formData)
        }
    }


    const apiFunction = async (formData) => {
        const userId = updateDrop.id;
        let jobId = location.state.jobDetails.id
        let api = await fetch(`${Constant.userApplicationStatus}/${userId}/${jobId}`, {
            method: 'POST',
            body: formData,
        })
        let result = await api.json()
        if (result.success == true) {
            console.log(result)
            // console.log('status set line 60 ')
        } else {
            console.log('status error line 62 ')
        }
    }

    const accepHandler = () => {
        const formData = new FormData();
        formData.append("status", 'hired');
        apiFunction(formData)
        setTerms(false)
    }

    const closeHandler = () => {
        setTerms(false)
        console.log(previous)
        setUpdatDrop({ ...updateDrop, application_status: previous })
    }

    return <>
        <tr key={updateDrop.id}>
            <td className="applicant-name text-center py-3">
                <button className="applicant-name bg-transparent border-0" onClick={() =>
                    navigate(`/${location.state.dashboardname}/profiledetails`, {
                        state: {
                            jobDetails: item,
                            dashboardname: location.state.dashboardname
                        },
                    })}
                >
                    {updateDrop.first_name} {updateDrop.last_name}</button>
            </td>
            <td className="text-center py-3">
                <Select
                style={{width:400}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={updateDrop.application_status}
                    onChange={(e) => handleChange(e)}
                >
                    {jobType?.map((value, index) => {
                        return <MenuItem key={index} value={value.val}>{value.application_status}</MenuItem>
                    })}
                </Select>
            </td>
        </tr>
        <Modal
            open={terms}
            // onClose={() => { setTerms(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="d-flex"
        >
            <Box className='job-description-modal bg-light w-50 m-auto p-5 elm' >
                <Typography className=" fw-bold text-center mb-3" variant="h5">Terms & Conditions</Typography>
                <Box className='mb-3'>
                <ul>
                    <li>All such referred clients must complete their account opening process & have their trading and demat account activated (should have received login & password via email) within 60 days of being referred on Zerodha’s website/application.</li>
                    <li>
                    If a referred client completes his/her account activation within 60 days of being referred, using the same contact number & email ID as the client inputted while referring had inputted at the time of referring such a client, it would be recognized as a “Completed Referral”.
                    </li>
                    <li>All such referred clients must complete their account opening process & have their trading and demat account activated (should have received login & password via email) within 60 days of being referred on Zerodha’s website/application.</li>
                    <li>
                    If a referred client completes his/her account activation within 60 days of being referred, using the same contact number & email ID as the client inputted while referring had inputted at the time of referring such a client, it would be recognized as a “Completed Referral”.
                    </li>
                </ul>
                </Box>

                <Box className='d-flex  justify-content-center gap-4'>


                    <Button className="mx-2 job-modal-btn text-white px-3" onClick={() => { closeHandler() }}>Close</Button>
                    <Button className="mx-2 job-modal-btn text-white px-3" onClick={() => { accepHandler() }}>Accept</Button>
                </Box>
            </Box>
        </Modal>
    </>
}
export default ApplicantTableRow