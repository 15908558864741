import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
//images
import main_logo from "../../assets/images/main_logo.png";
import Glogo from "../../assets/images/Glogo.png";
import slacklogo from "../../assets/images/slacklogo.png";
import windowslogo from "../../assets/images/windowslogo.png";
import wordpresslogo from "../../assets/images/wordpresslogo.png";
import eyeImg from '../../assets/images/eye.png'
import TextField from "@mui/material/TextField";
import { Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader";
import { Row, Col, Container } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";


// api file
import * as Constant from "../Constant";

function EmployerLogin() {
  const navigate = useNavigate();
  const recaptcha = useRef();
  const [state, setState] = useState({
    email: "",
    password: "",
    emailError: null,
    passwordError: null,
    isLoading: false,
  });

  const [showPassword, setShowPassword] = useState("password")

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!state.email) {
      emailError = "Email Cannot Be Empty.";
    } else {
      if (!reg.test(state.email)) {
        emailError = "Please Enter a Valid Email Address.";
      }
    }

    if (!state.password) {
      passwordError = "Password Cannot be empty.";
    }

    if (emailError || passwordError) {
      setState({
        ...state,
        passwordError,
        emailError,
      });
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();

    if (validate() && captchaValue) {
      // setState({
      //   ...state,
      //   emailError: null,
      //   passwordError: null,
      // });
      onLogin();
    } else {
      let message = `Please verify the reCAPTCHA!`;
      message && toast(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const notify = (value) => {
    // alert(value);
    // window.location.reload();
    toast(value);
  };

  const storeUserId = async (userId) => {
    try {
      localStorage.setItem("userId", userId);
      console.log(userId);
    } catch (e) { }
  };

  const storeRoleId = async (userId) => {
    try {
      localStorage.setItem("roleId", userId);
      console.log(userId);
    } catch (e) { }
  };

  const storeToken = (value) => {
    try {
      localStorage.setItem("token", value);
      console.log("token", value);
    } catch (e) {
      // saving error
    }
  };

  const onSignup = () => {
    navigate("/employee/register");
  };

  const onLogin = async () => {
    setState({ isLoading: true });

    const formData = new FormData();

    formData.append("email", state.email);
    formData.append("password", state.password);

    fetch(Constant.LoginUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.success === true) {
          storeRoleId(data.data.role_id);
          if (data.data.role_id === 2 || data.data.role_id === 1) {
            toast("Your login details are incorrect. Please try again.", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setState({ ...state, isLoading: false });
          } else {
            storeUserId(data.data.userId);
            storeToken(data.data.token);
            setState({
              email: "",
              password: "",
              isLoading: false,
            });
            console.log(data.data.organization_name)
            localStorage.setItem('organization_name', data.data.organization_name)
            navigate("/employer/profile");
          }
        } else {
          // notify(data.message);
          toast(data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setState({ ...state, isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message === "Failed to fetch") {
          toast("Oops!!!... There is no Internet Connection", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast(error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };


  const passwordHandler = () => {
    if (showPassword === 'password') {
      setShowPassword('text')
      return;
    }
    setShowPassword('password')
  }

  const submitHandler = (e) => {
    if (e.keyCode === 13) {
      onSubmit(e)
    }
  }

  return (
    <div className="main_class">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Employer - Login</title>
      </Helmet>
      <div className="card">
        <div className="center_class">
          <img
            src={main_logo}
            style={{
              width: "130px",
              marginTop: "25px",
            }}
            alt="Qudo"
          ></img>
        </div>
        <div className="center_class">
          <text className="welcometext">Welcome To Qudo</text>
        </div>


        <Container onKeyDown={(e) => submitHandler(e)}>
          <Row>
            <Col md="1" lg="1" xl="1"></Col>
            <Col sm="12" xs="12" md="5" lg="5" xl="5">
              {" "}
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                className="text_field"
                onChange={(event) =>
                  setState({ ...state, email: event.target.value, emailError: '' })
                }
                required
              />
              {!!state.emailError && (
                <Typography style={{ color: "red", marginLeft: "10px" }}>
                  {state.emailError}
                </Typography>
              )}
            </Col>
            <Col sm="12" xs="12" md="5" lg="5" xl="5">
              <div className="position-relative">
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  className="text_field"
                  type={showPassword}
                  onChange={(event) =>
                    setState({ ...state, password: event.target.value, passwordError: '' })
                  }
                  required
                />
                <div className="show-password" onClick={() => passwordHandler()}>
                  <img src={eyeImg} alt='password-hide' width={20} height={20} />
                </div>
              </div>
              {!!state.passwordError && (
                <Typography style={{ color: "red", marginLeft: "10px" }}>
                  {state.passwordError}
                </Typography>
              )}
            </Col>
            <Col md="1" lg="1" xl="1"></Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <div className="d-flex justify-content-center mb-3">
                <ReCAPTCHA sitekey="6LcVCokoAAAAABcrU4b4CC_rZ7ogeOpK2bXcw8FF"  ref={recaptcha} />
              </div>

              <Button
                disabled={state.isLoading}
                variant="contained"
                onClick={(e) => onSubmit(e)}
          
                style={{
                  width: "300px",
                  height: "35px",
                  borderRadius: "10px",
                  border: "2px #2383EF solid",
                  backgroundColor: "#2383EF",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                {state.isLoading ? (
                  <ClipLoader
                    type="TailSpin"
                    color="#ffffff"
                    height="20"
                    width="20"
                  />
                ) : (
                  "LOGIN"
                )}
              </Button>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <Button
                variant="contained"
                style={{
                  width: "300px",
                  height: "35px",
                  borderRadius: "10px",
                  border: "2px #44C948 solid",
                  backgroundColor: "#44C948",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
                onClick={() => onSignup()}
              >
                Sign Up
              </Button>
             
            </Col>
          </Row>


          <Row>
            <Col className="text-center mt-3">
              <span
                className="forgot-password"
                style={{ color: "#707070", cursor: "pointer" }}
                onClick={() => navigate("/employee/forgotpassword")}
              >
                Forgot Password?
              </span>
            </Col>
          </Row>
          <ToastContainer />
          <div className="center_class_qudo">
            <a className="choose_qudo my-2" target="_blank" href='https://www.qudo.me'><text >Why choose QUDO ?</text></a>
          </div>
          <Row className="text-center  ">
            <Col>
              <text className="brandtext">Brands we work with</text>
            </Col>
          </Row>
          <Row className="text-center ">
            <Col>
              <div style={{ flexDirection: "row" }}>
                <img
                  className="mr-2"
                  src={slacklogo}
                  alt="slack"
                ></img>
                <img className="mr-2" src={Glogo} alt="glogo"></img>
                <img
                  src={windowslogo}
                  alt="windows"
                ></img>
                <img src={wordpresslogo} alt="wordpress"></img>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="contacttextdiv">
                <text className="contacttext">Contact Us</text>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default EmployerLogin;
