import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Constant from "../Constant";
import Dropdown from "react-dropdown";
import { Typography, CircularProgress, Box, Modal, Button, Select, MenuItem } from "@mui/material";
import Header from "../../commonComponent/Header";
import ApplicantTableRow from "./ApplicantTableRow";

const Applicant = () => {
  const navigate = useNavigate()
  // const [dropDownval, setDropDownval] = useState()
  const location = useLocation()
  const [state, setState] = useState({
    open: false,
    appliedjobs: [],
    elm: [],
    searchText: "",
    isLoading: false,

  });
  const [query, setQuery] = useState("")


  useEffect(() => {
    UserAppliedJobList()
  }, [])

  const UserAppliedJobList = async () => {
    let jobId = location.state.jobDetails.id
    setState({ ...state, isLoading: true });
    let api = await fetch(`${Constant.userAppliedJob}/${jobId}`)
    let result = await api.json()
    if (result.success == true) {
      console.log('-0887',result)
      setState({ ...state, appliedjobs: result.data, isLoading: false })
    } else {
      console.log('please check line no 36 at Applicats.js ')
    }
  }

  let applied_jobs = state.appliedjobs

  return <>
    <div>
      {
        location.state.dashboardname == 'employee' ?
          <Header logout={"/"} tabtitle={"Qudo - Applicants"} logoname={"Employer"} logoNav={"/employer/dashboard"}
            settingItemList={[{ 'name': 'Employee Dashboard', 'navigate': '/employer/dashboard' },
            { 'name': ' Employeer Upload Role', 'navigate': '/employer/uploadjob' }
            ]} /> :
          <Header logout={"/admin/login"} tabtitle={"Qudo - Applicants"} logoname={"Admin"} logoNav={"/admin/dashboard"} />
      }
      <main>
        <div className="position-relative">
          {location.state.dashboardname == 'employee' ? <button className="border position-absolute px-4 py-2 rounded-pill" style={{ left: 50, backgroundColor: "#E0EFFF" }} onClick={() => navigate('/employer/dashboard')}>BACK</button> :
            <button className="border position-absolute px-4 py-2 rounded-pill" style={{ left: 50 }} onClick={() => navigate("/admin/dashboard")}>BACK</button>
          }
          <h1 className="my-4 text-center">ALL USERS</h1>
        </div>
        <div className="d-flex mb-5">
          <input placeholder="Search User Name" className="m-auto py-2 rounded-pill searchInput "
            onChange={event => setQuery(event.target.value)} />
        </div>
        <table className='w-75 m-auto table-striped'>
          <thead>
            <tr className="h3 text-center table-header text-uppercase">
              <th className="w-50 p-2  py-3">User Name</th>
              <th className="w-50 p-2  py-3">Status</th>
            </tr>
          </thead>
          <tbody className="position-relative">
            {
              applied_jobs.filter((item) => {
                if (query === '') {
                  return item
                } else if (item.first_name.toLowerCase().includes(query.toLocaleLowerCase())) {
                  return item
                }
              }).map((item, index) => {
                return (
                  <ApplicantTableRow item={item}   />
                )
              })
            }
            {state.isLoading && <tr className="h4">
              <td colSpan="2" className="text-center p-5"> <CircularProgress size='55px' /></td>
            </tr>
            }
            {
              (applied_jobs.length === 0 && state.isLoading == false) && <tr className="h4">
                <td colSpan="2" className="text-center p-5"> NO DATA ABAILABLE</td>
              </tr>
            }

          </tbody>
        </table>

      </main>
    </div>

  </>
}
export default Applicant