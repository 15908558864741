import React, { useEffect, useState } from "react";
import Header from "../../commonComponent/Header"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Constant from "../Constant";

const CommissionAdmin = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [query, setQuery] = useState("")
  const [commissionData, setCommissionData] = useState([])

  useEffect(() => {
    getCommission();
  }, []);

  const getCommission = async () => {
    const userId = localStorage.getItem("userId");

    await fetch(Constant.Commission + "/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setCommissionData(data.data)
        }
      });
  };

  return <>
    <Header logout={"/"} tabtitle={"Qudo - Applicants"} logoname={"Admin"} logoNav={"/admin/dashboard"}
      settingItemList={[{ 'name': 'Admin Dashboard', 'navigate': '/admin/dashboard' },
      { 'name': ' Admin Upload Role', 'navigate': '/admin/uploadjob' }
      ]} />

    <div className='position-relative'>
      <button className="border position-absolute px-4 py-2 rounded-pill" style={{ background: '#EDF5FE', left: 50, top: 50 }} onClick={() => navigate("/admin/dashboard")}>Back to Dashboard</button>
      <div className="d-flex my-5">
        <input placeholder="Search User Name" className="m-auto py-2 rounded-pill searchInput "
          onChange={event => setQuery(event.target.value)}
        />
      </div>
    </div>
    <table className='commission_table'>
      <tr className='commission_heading'>
        <th>Company Name</th>
        <th>Employee</th>
        <th>Referrer Name</th>
        <th>Hired At</th>
        <th>Commission to QUDO</th>
        <th>Commission to Referral</th>
      </tr>
     
      {
        commissionData.filter((item) => {
          if (query === '') {
            return item
          } else if (item?.employerName.toLowerCase().includes(query.toLocaleLowerCase())) {
            return item
          }
        }).map((item, index) => {
          return (
            <>
            {item.employerName  &&
              <tr className='commission_rows'>
              <td>{item.employerName}</td>
              <td>{item.employeeName}</td>
              <td>{item.referrerName}</td>
              <td>{item.hired_at?.split(" ", 1)}</td>
              <td>{item.employer_commission_amount}</td>
              <td>{item.referrer_commission_amount}</td>
            </tr>
            }
          
            </>
          )
        })
      }
    </table>
  </>
}

export default CommissionAdmin