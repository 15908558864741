import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

//images
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Row, Col, Container } from "reactstrap";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import * as Constant from "../Constant";
import moment from "moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "./TextEditor";
import Header from "../../commonComponent/Header";

// import React, { Component } from 'react';

const currencies = [
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
  {
    value: "INR",
    label: "₹",
  },
];

function EditJobRole() {
  const navigate = useNavigate();
  const [Users, setUsers] = useState([]);
  const { id } = useParams();
  const [countryArray, setCountryArray] = useState([]);
  const [jobDescription, setJobDescription] = useState("");
  const [jobDescriptionToUpdate, setJobDescriptionToUpdate] = useState("");
  const [industries, setIndustries] = useState([]);

  var today = new Date(),
    time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  const [state, setState] = useState({
    CountryList: [],
    country_id: "",
    country_name: "",

    StateList: [],
    state_id: "",
    state_name: "",

    date: moment(new Date()).format("YYYY-MM-DD"),
    time: "",

    job_title: "",
    organization_name: "",
    organization_value: "",
    salary: "",
    currency: "",
    location: "",
    sector: "",
    industry: "",
    ad_start_date: moment(new Date()).format("YYYY-MM-DD"),
    ad_start_time: time,
    ad_end_date: moment(new Date()).format("YYYY-MM-DD"),
    ad_end_time: time,
    job_description: "",
    image: "",
    // ad_text: "",

    isLoading: false,
    checked: false,
    open: false,
    is_called: 0,
  });

  const logout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/admin/login";
  };

  const handleChange = (event) => {
    setState({ ...state, currency: event.target.value });
  };

  const handleButtonClick = () => {
    if (state.open === false) {
      setState({ ...state, open: true });
    } else {
      setState({ ...state, open: false });
    }
  };

  const handleEditorChange_value = (job_description_data) => {
    if (job_description_data) {
      setJobDescriptionToUpdate(job_description_data);
    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  useEffect(() => {
    if (state.is_called == 0) {
      getCountry();
      getUsers();
      getIndustries();
    }
  }, []);

  const getIndustries = async () => {
    setState({ ...state, isLoading: true });

    fetch(Constant.GetIndustries, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setIndustries(data.data);
        }
      });
  };

  const setIndustry = (e) => {
    setState({
      ...state,
      industry: e.label.props.value,
    });
  };

  const getUsers = async () => {
    setState({ ...state, isLoading: true });

    fetch(Constant.GetUsers, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setUsers(data.data);
        }
      });
  };

  const setUser = (e) => {
    setState({
      ...state,
      organization_name: e.label.props.children,
      organization_value: e.label.props.value,
    });
  };

  const setCountryId = (value) => {
    try {
      localStorage.setItem("Country_id", value.value.key);
      localStorage.setItem("Country_name", value.label.props.value);
      console.log("local storage", value);
      getStateList();
    } catch { }
  };

  const updateJobRole = async () => {
    console.log(jobDescription);
    setState({ ...state, isLoading: true });

    var countryName = localStorage.getItem("Country_name");
    var stateName = localStorage.getItem("State_name");

    const formData = new FormData();
    formData.append("job_title", state.job_title);
    formData.append("organization_name", state.organization_name);
    formData.append("salary", state.salary);
    formData.append("currency", state.currency);
    formData.append("location", countryName);
    formData.append("sector", stateName);
    formData.append("industry", state.industry);
    formData.append("ad_start_date", state.ad_start_date);
    formData.append("ad_start_time", state.ad_start_time);
    formData.append("ad_end_date", state.ad_end_date);
    formData.append("ad_end_time", state.ad_end_time);
    if (jobDescriptionToUpdate) {
      formData.append("job_description", jobDescriptionToUpdate);
    } else {
      formData.append("job_description", jobDescription);
    }
    formData.append("userId", state.organization_value);
    // formData.append("image", state.image);
    // formData.append("ad_text", state.ad_text);

    await fetch(Constant.EditJobRole + "/" + id, {
      method: "POST",
      body: formData,
    })
      .then((Response) => Response.json())
      .then((data) => {
        console.log(data);
        navigate("/admin/dashboard");
      });
  };

  const getJobDetails = async () => {
    if (state.is_called == 0) {
      setState({ ...state, isLoading: true });
      setState({ ...state, is_called: 1 });

      await fetch(Constant.GetJobDetails + "/" + id, {
        method: "GET",
      })
        .then((Response) => Response.json())
        .then((data) => {
          // console.log(data.data.job_title);
          console.log(data.data.industry);
          if (data.success === true) {
            console.log("des", data);
            setState({
              ...state,
              job_title: data.data.job_title,
              organization_name: data.data.organization_name,
              salary: data.data.salary,
              location: data.data.location,
              sector: data.data.sector,
              industry: data.data.industry,
              ad_start_date: moment(data.data.ad_start_date).format(
                "YYYY-MM-DD"
              ),
              ad_start_time: data.data.ad_start_time,
              ad_end_date: moment(data.data.ad_end_date).format("YYYY-MM-DD"),
              ad_end_time: data.data.ad_end_time,
              // ad_text: data.data.ad_text,
              job_description: data.data.job_description,
              currency: data.data.currency,
            });
            setJobDescription(data.data.job_description);
          }
        });
    }
  };

  const setStateId = (value) => {
    try {
      localStorage.setItem("State_id", value.value.key);
      localStorage.setItem("State_name", value.label.props.value);
    } catch { }
  };

  const getCountry = async () => {
    setState({ ...state, isLoading: true });
    setState({ ...state, is_called: 1 });
    fetch(Constant.GetCountryList, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.success === true) {
          console.log("data", data.data);
          setCountryArray(data.data);
          getJobDetails();
          setState({ ...state, CountryList: data.data });
          setState({ ...state, is_called: 1 });
        }
      });
  };

  // const getCountry = async () => {
  //   setState({ ...state, isLoading: true });

  //   fetch(Constant.GetCountryList, {
  //     method: "GET",
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success === true) {
  //         setState({ ...state, CountryList: data.data });
  //         console.log(state.CountryList);
  //       }
  //     });
  // };
  const getStateList = async () => {
    setState({ ...state, isLoading: true });

    var countryid = localStorage.getItem("Country_id");

    await fetch(Constant.GetStateList + "/" + countryid, {
      method: "GET",
    })
      .then((Response) => Response.json())
      .then((data) => {
        if (data.success === true) {
          setState({ ...state, StateList: data.data });
        }
      });
  };

  const handleChangeEndDate = (e) => {
    // console.log("end date:" + e.target.value);
    setState({ ...state, ad_end_date: e.target.value });
  };

  const changeStartTime = (e) => {
    // console.log("start time:" + e.target.value);
    setState({ ...state, ad_start_time: e.target.value });
  };

  const changeEndTime = (e) => {
    // console.log("end time:" + e.target.value);
    setState({ ...state, ad_end_time: e.target.value });
  };

  const handleImageChange = (e) => {
    setState({ ...state, image: e.target.files[0] });
  };

  const handleChangeStartDate = (e) => {
    // console.log("start date:" + e.target.value);
    setState({ ...state, ad_start_date: e.target.value });
  };

  const UserId = localStorage.getItem("userId");
  if (UserId == 1) {
    return (
      <div>
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>Admin - Edit Role</title>
        </Helmet>
        <div className="header_class">
          <img className="chart_img" src={chart} alt="chart"></img>
          <img
            src={Qudo_light}
            alt="qudo"
            className="qudo_img_upload_role"
            onClick={() => { navigate('/admin/dashboard') }}
          ></img>
          <text className="company_text_upload_role">Company</text>
          <div>
            <div class="button" type="button" onClick={handleButtonClick}>
              <img className="setting_img" src={settings} alt="qudo"></img>
            </div>
            {state.open && (
              <div class="dropdown">
                <ul>
                  <li onClick={logout}>Logout</li>
                </ul>
              </div>
            )}
          </div>
          <div>
            <text className="upload_role_text">Edit Job Role</text>
          </div>
        </div> */}
      <Header logout={"/admin/login"} tabtitle={"Admin - Edit Role"} logoname={"Admin"} logoNav ={"/admin/dashboard"}/>

        <Card className="upload_code_card">
          <CardContent>
            <Card className="roles_text_card">
              <CardContent>
                <text className="roles_text">Role Details</text>
              </CardContent>
            </Card>
            <Button
              variant="contained"
              className=" Upload_role"
              onClick={updateJobRole}
            >
              Submit
            </Button>
          </CardContent>

          <Container>
            <Row className="mt-5">
              <Col sm="12" md="6" lg="6" xl="6" xs="12">
                <TextField
                  id="outlined-basic"
                  label="Job Title"
                  variant="outlined"
                  value={state.job_title}
                  className="text_field"
                  style={{ border: "1px solid #fff" }}
                  onChange={(event) =>
                    setState({ ...state, job_title: event.target.value })
                  }
                />

                <Row>
                  <Col sm="12" md="6" lg="6" xs="12" xl="6" className="mt-2">
                    {" "}
                    {/* <TextField
                      id="outlined-basic"
                      label="Organization Name"
                      variant="outlined"
                      value={state.organization_name}
                      className="text_field "
                      style={{ border: "1px solid #fff" }}
                      onChange={(event) =>
                        setState({
                          ...state,
                          organization_name: event.target.value,
                        })
                      }
                    /> */}
                    <FormControl sx={{ m: 1 }} variant="standard">
                      <Typography id="demo-customized-select-label">
                        Organization Name
                      </Typography>
                      <Dropdown
                        options={Users.map((value, key) => (
                          <Typography key={key} value={value.id}>
                            {value.first_name}
                          </Typography>
                        ))}
                        value={state.organization_name}
                        onChange={(e) => setUser(e)}
                        placeholder="Select an option"
                      />
                    </FormControl>
                    {!!state.organization_nameError && (
                      <Typography className="error_text">
                        {state.organization_nameError}
                      </Typography>
                    )}
                  </Col>
                  <Col sm="12" md="6" lg="6" xs="12" xl="6" className="mt-4">
                    <Stack direction="row">
                      <AntSwitch
                        className="text_field"
                        defaultChecked
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography
                        className="mt-2 ml-4"
                        style={{ color: "#707070", fontWeight: "bold" }}
                      >
                        Hide Organization
                      </Typography>
                    </Stack>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6" lg="6" xs="12" xl="6" className="mt-3">
                    <Row>
                      <Col sm="12" lg="3" md="3" xl="3" xs="12">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Select"
                          defaultValue={state.currency}
                          value={state.currency}
                          className="text_field mt-2"
                          onChange={handleChange}
                        // helperText="Please select currency"
                        >
                          {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Col>
                      <Col sm="12" lg="8" md="8" xl="8" xs="12">
                        <TextField
                          id="outlined-basic"
                          label="Salary"
                          value={state.salary}
                          variant="outlined"
                          className="text_field mt-2"
                          style={{ border: "1px solid #fff" }}
                          onChange={(event) =>
                            setState({ ...state, salary: event.target.value })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12" md="6" lg="6" xs="12" xl="6" className="mt-4">
                    <Stack direction="row">
                      <AntSwitch
                        className="text_field"
                        defaultChecked
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography
                        className="mt-2 ml-4"
                        style={{ color: "#707070", fontWeight: "bold" }}
                      >
                        Hide Salary
                      </Typography>
                    </Stack>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6" lg="6" xs="12" xl="6" className="mt-1">
                    <FormControl sx={{ m: 1 }} variant="standard">
                      <Typography id="demo-customized-select-label">
                        Country
                      </Typography>
                      <Dropdown
                        options={countryArray.map((value, key) => (
                          <Typography key={key} value={value.country_name}>
                            {value.country_name}
                          </Typography>
                        ))}
                        value={state.location}
                        onChange={setCountryId}
                        placeholder="Select an option"
                      />
                      {!!state.country && (
                        <Typography className="error_text">
                          {state.organization_nameError}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col sm="12" md="6" lg="6" xs="12" xl="6" className="mt-4">
                    <Stack direction="row">
                      <AntSwitch
                        className="text_field"
                        defaultChecked
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography
                        className="mt-2 ml-4"
                        style={{ color: "#707070", fontWeight: "bold" }}
                      >
                        Hide Organisation
                      </Typography>
                    </Stack>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6" lg="6" xs="12" xl="6" className="mt-1">
                    <FormControl sx={{ m: 1 }} variant="standard">
                      <Typography id="demo-customized-select-label">
                        State
                      </Typography>
                      <Dropdown
                        options={state.StateList.map((value, key) => (
                          <Typography key={key} value={value.state_name}>
                            {value.state_name}
                          </Typography>
                        ))}
                        value={state.sector}
                        onChange={setStateId}
                        placeholder="Select an option"
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Col>

              <Col sm="12" md="6" lg="6" xl="6" xs="12">
                <Row>
                  <Col sm="12" md="6" lg="6" xs="12" xl="6" className="mt-2">
                    <TextField
                      id="date"
                      label="Advert Start Date"
                      type="date"
                      value={state.ad_start_date}
                      onChange={handleChangeStartDate}
                      className="text_field"
                      format="YYYY-MM-DD"
                      defaultValue={state.ad_start_date}
                      sx={{ width: 210 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      id="time"
                      label="Advert Start Time"
                      type="time"
                      value={state.ad_start_time}
                      style={{ marginLeft: 10, marginTop: 15 }}
                      defaultValue={state.ad_start_time}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      sx={{ width: 210 }}
                      onChange={changeStartTime}
                    />
                  </Col>
                  <Col className="mt-3" sm="12" md="6" lg="6" xs="12" xl="6">
                    {/* <TextareaAutosize
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="Advert Text"
                      style={{
                        width: 250,
                        height: 100,
                        border: "1px solid #2383EF",
                        borderRadius: "8px",
                      }}
                      value={state.ad_text}
                      onChange={(event) =>
                        setState({ ...state, ad_text: event.target.value })
                      }
                    /> */}
                    <FormControl sx={{ m: 1 }} variant="standard">
                      <Typography id="demo-customized-select-label">
                        Industry
                      </Typography>
                      <Dropdown
                        options={industries.map((value, key) => (
                          <Typography key={key} value={value.industries}>
                            {value.industries}
                          </Typography>
                        ))}
                        value={state.industry}
                        onChange={(e) => setIndustry(e)}
                        placeholder="Select an option"
                      />
                    </FormControl>
                    {!!state.industryError && (
                      <Typography className="error_text">
                        {state.organization_nameError}
                      </Typography>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col className="mt-3" sm="12" md="6" lg="6" xs="12" xl="6">
                    <TextField
                      id="date"
                      label="Advert End Date"
                      type="date"
                      value={state.ad_end_date}
                      style={{ marginLeft: 10, marginTop: 15 }}
                      defaultValue={state.ad_end_date}
                      sx={{ width: 210 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChangeEndDate}
                    />

                    <TextField
                      id="time"
                      label="Advert End Time"
                      type="time"
                      value={state.ad_end_time}
                      style={{ marginLeft: 10, marginTop: 30 }}
                      defaultValue={state.ad_end_time}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      sx={{ width: 210 }}
                      onChange={changeEndTime}
                    />
                  </Col>
                  <Col className="mt-4" sm="12" md="6" lg="6" xs="12" xl="6">
                    {/* <TextareaAutosize
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="Job description Person Specification"
                      style={{
                        width: 250,
                        height: 100,
                        border: "1px solid #2383EF",
                        borderRadius: "8px",
                      }}
                      value={jobDescription}
                      onChange={(event) =>
                        setState({
                          ...state,
                          job_description: event.target.value,
                        })
                      }
                    /> */}
                    {/* <Editor editorState={editorState} onEditorStateChange={handleEditorChange}/> */}
                    {/* <Editor
                      editorStyle={{ border: "1px solid #C0C0C0" }}
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={handleEditorChange}
                      // placeholder={jobDescription}
                    /> */}
                    <TextEditor
                      jobDescription={jobDescription}
                      handleEditorChange_value={handleEditorChange_value}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mt-3" sm="12" md="6" lg="6" xs="12" xl="6">
                    {/* <TextField
                      id="file"
                      label="Upload Logo"
                      type="file"
                      style={{ marginLeft: 10, marginTop: 30 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      sx={{ width: 210 }}
                      onChange={handleImageChange}
                    /> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  } else {
    return <Navigate to="/" replace />;
  }
}

export default EditJobRole;
