import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useNavigate,
} from "react-router-dom";

//admin files
import AdminLogin from "../src/components/admin/AdminLogin";
import AdminDashboard from "../src/components/admin/AdminDashboard";
import AdminForgotPassword from "../src/components/admin/AdminForgotPassword";
import UploadRoles from "./components/admin/UploadRoles";
import ViewUser from "./components/admin/ViewUser";
import EditJobRole from "./components/admin/EditJobRole";

//Employer Files
import EmployerLogin from "./components/employer/EmployerLogin";
import EmployerRegister from "./components/employer/EmployerRegister";
import EmployerDashboard from "./components/employer/EmployerDashboard";
import EmployerForgotPassword from "./components/employer/EmployerForgotPassword";
import EmployeerUploadRole from "./components/employer/EmployeerUploadRole";
import EmployerView from "./components/employer/EmployerView";
import EmployeeEditJobRole from "./components/employer/EmployeeEditJobRole";
import PublicRoutes from "./routes/publicRoutes";
import PrivateRoutes from "./routes/privateRoutes";
import Applicants from "./components/admin/Applicants";
import EmployerUpload from "./components/employer/EmployerUpload";
import UserProfileDetails from "./components/employer/UserProfileDetails";
import CommissionRole from "./components/employer/CommissionRole";
import CommissionAdmin from "./components/admin/CommisionAdmin";

function App() {
  const [signoutTime, setSignoutTime] = useState(3600000);
  const [warningTime, setWarningTime] = useState(4000000);
  let warnTimeout;
  let logoutTimeout;

  const warn = () => {
    console.log("Warning");
  };
  const logout = () => {
    window.location.reload();
    localStorage.clear();
    return <Navigate to="/" replace />;
  };

  const destroy = () => {
    console.log("Session destroyed");
  };
  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime);
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  // render() {
  return (
    <div className="App d-flex flex-column h-100">
      <BrowserRouter basename="/">
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/employer/dashboard" element={<EmployerView />} />
            <Route path="/employer/profile" element={<EmployerDashboard />} />
            <Route path="/employer/uploadjob" element={<EmployerUpload />} />
            <Route path="/employee/editjob/:id" element={<EmployerUpload />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/uploadjobs" element={<UploadRoles />} />
            <Route path="/admin/editjob/:id" element={<UploadRoles />} />
            <Route path="/admin/viewuser" element={<ViewUser />} />
            <Route path="/admin/appliedUsers" element={<Applicants />} />
            <Route path="/employee/appliedUsers" element={<Applicants />} />
            <Route path="/employee/profiledetails" element={<UserProfileDetails />} />
            <Route path="/admin/profiledetails" element={<UserProfileDetails />} />
            <Route path="/employer/commissiondetails" element={<CommissionRole />} />
            <Route path="/admin/admincommission" element={<CommissionAdmin />} />
          </Route>
          <Route element={<PublicRoutes />}>
            <Route exact path="/" element={<EmployerLogin />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route
              path="/employee/register"
              element={<EmployerRegister />}
            />
            <Route
              path="/admin/forgotpassword"
              element={<AdminForgotPassword />}
            />
            <Route
              path="/employee/forgotpassword"
              element={<EmployerForgotPassword />}
            />
            </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
  // }
}

export default App;
